import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Img from "gatsby-image";
import Helmet from "react-helmet";
import LandingPage from "../components/LandinPage";
import Pageroll from "../components/PageRoll";
import useSiteMetadata from '../helper/SiteMetadata'



export const IndexPageTemplate = ({ image }) => {


  return (
    <div>

      <Img
        className="mainimage"
        fadeIn={true}
        backgroundColor={"grey"}
        loading={"lazy"}
        fluid={!!image.childImageSharp ? image.childImageSharp.fluid : image}
        alt="Dekko"
      />
      <div className="full-width-image margin-top-0 "></div>
      <Pageroll />

    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter, fields } = data.markdownRemark;
  const { title, image, subheading, description, og_image } = frontmatter
  const { siteUrl } = useSiteMetadata()
  const structuredData = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'Dekko',
    image: `${image.publicURL}`,
    '@id': '',
    url: 'https://awesome-agnesi-30e8ff.netlify.app',
    telephone: `06 30 8682021`,
  })

  return (
    <Layout>
      <Helmet>
        <title>{"Dekko|" + title}</title>
        <meta name="description" content={description} />
        <meta name="theme-color" content="#1D1D1D" />
        <meta name="keywords" content="keyword 1, keyword 2, keyword 3" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={title} />
        <meta property="og:url" content={siteUrl} />
        <meta
          property="og:image"
          content={siteUrl + image.childImageSharp.og_image.src}
        />
        <meta property="og:image:alt" content="Dekko" />
        <meta property="og:description" content={description} />
        <meta property="og:locale" content="hu_HU" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={siteUrl + image.childImageSharp.og_image.src}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subheading={frontmatter.subheading}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: structuredData }}
      ></script>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      field: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        title
        description
        image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                src
                srcSet
                presentationWidth
                presentationHeight
            }
            og_image: fluid(maxWidth: 500, maxHeight: 500, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
                src
                srcSet
            }
          }
        }
        subheading
      }
    }
  }
`;
