import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "../../helper/PreviewCompatibleImage";
import { LangContext } from "../../context/langContext";
import lang from "../../helper/lang.json";


const PageRoll = (props) => {

  const { data } = props;
  const { edges: posts } = data.allMarkdownRemark;
  const { state } = useContext(LangContext);
  const { state2 } = useContext(LangContext);
  const isInvert = state2.isNight;
  const invertClass = isInvert ? "section" : "section section-invert";
  const posts2 = posts.filter((elem) => {
    return elem.node.frontmatter.lang === state.lang;
  });
  return (

    <section className={`${invertClass} section--gradient`}>
      <div className="container">
        <div className="columns  has-text-centered is-vcentered">
          <div className="column is-vcentered">
            {posts2 &&
              posts2.map(({ node: post }) => (
                <div
                  style={{
                    position: "relative",
                    textAlign: "center",
                    marginBottom: "1em",
                  }}
                >
                  {post.frontmatter.image ? (
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: post.frontmatter.image,
                        alt: `featured image thumbnail for post ${post.title}`,
                      }}
                    />
                  ) : null}
                  <Link
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      boxShadow:
                        "0.5rem 0 0 rgba(255, 255, 255, .8), -0.5rem 0 0 rgba(255, 255, 255, .8)",
                      backgroundColor: "rgba(255, 255, 255, .4)",
                      color: "grey",
                      padding: "1rem",
                    }}
                    className={`has-text-weight-bold is-size-3  tracking-in-expand`}
                    to={`${lang[state.lang].slug +
                      post.fields.slug.slice(1, -3)}`}
                  >
                    {post.frontmatter.title}
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

PageRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query PageRollQuery {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              templateKey: {
                in: ["about-page", "service-page", "product-page"]
              }
            }
          }
        ) {
          edges {
            node {
              frontmatter {
                image {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                      src
                      srcSet
                    }
                  }
                }
                title
                lang
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <PageRoll data={data} count={count} />}
  />
);
